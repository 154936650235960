<template>
  <router-link :to="{ name: to }">&#10094; Back</router-link>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    to: {
      type: String,
      required: true
    }
  }
};
</script>
