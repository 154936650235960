<template>
  <div class="card h-100">
    <div class="card-image">
      <div class="card-icon">
        <i class="fas" :class="fileIcon"></i>
      </div>
    </div>
    <div class="card-content">
      <div class="content has-text-centered">
        <span>{{ file.fileName }}</span>
      </div>
    </div>
    <div class="card-footer">
      <div class="card-footer-item pointer" @click="shareDropdownOpen = !shareDropdownOpen">
        <div class="dropdown" :class="{ 'is-active': shareDropdownOpen }">
          <div class="dropdown-trigger">
            <i class="fas fa-share-alt"></i>
          </div>
          <div class="dropdown-menu share-menu" role="menu">
            <div class="dropdown-content">
              <div class="dropdown-item" style="min-width: 100%">
                <copy-input :value="url" @copy="onCopyUrl"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="card-footer-item pointer" @click="downloadFile">
        <i class="fas fa-cloud-download-alt"></i>
      </span>
      <span class="card-footer-item pointer" @click="showDeleteModal = true" v-if="showDelete">
        <i class="fas fa-trash"></i>
      </span>
    </div>
    <confirm-modal v-if="showDeleteModal" @cancel="showDeleteModal = false" @confirm="deleteFile"/>
  </div>
</template>

<script>
import { toast } from "bulma-toast";
import CopyInput from "@/components/CopyInput";
import ConfirmModal from "@/components/modals/ConfirmModal";
import util from "@/util";

export default {
  name: "File",
  components: { CopyInput, ConfirmModal },
  props: {
    file: {
      type: Object,
      required: true
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    fileIcon() {
      const nameSplit = this.file.fileName.split('.');
      const extension = nameSplit[nameSplit.length - 1].toLowerCase();
      switch (extension) {
        case 'pdf':
          return 'fa-file-pdf';
        case 'docx':
        case 'doc':
          return 'fa-file-word';
        case 'xlsx':
        case 'xls':
          return 'fa-file-excel';
        case 'ppt':
        case 'pptx':
          return 'fa-file-powerpoint';
        case 'txt':
          return 'fa-file-alt';
        case 'csv':
          return 'fa-file-csv';
        case 'zip':
        case 'tar':
        case 'gz':
        case 'tgz':
        case 'xz':
          return 'fa-file-archive';
        case 'py':
        case 'java':
        case 'php':
        case 'html':
        case 'css':
          return 'fa-file-code';
        case 'mp4':
        case 'mov':
        case 'ogg':
        case 'wmv':
        case 'webm':
        case 'flv':
        case 'avi':
          return 'fa-file-video';
        case 'mp3':
        case 'm4a':
        case 'aac':
        case 'wma':
        case 'wav':
          return 'fa-file-audio';
        case 'png':
        case 'gif':
        case 'jpg':
        case 'jpeg':
        case 'svg':
        case 'tif':
        case 'tiff':
        case 'heic':
          return 'fa-file-image';
        default:
          return 'fa-file'
      }
    },
    url() {
      return util.urlOf(this.file);
    }
  },
  data() {
    return {
      showDeleteModal: false,
      shareDropdownOpen: false
    }
  },
  methods: {
    downloadFile() {
      this.$store.commit('setIsLoading', true);
      this.$http({
        method: 'get',
        url: `/files/${this.file.slug}/download`,
        responseType: 'arraybuffer'
      }).then(response => {
        this.$store.commit('setIsLoading', false);
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.file.fileName;
        link.click();
      });
    },
    onCopyUrl() {
      toast({
        message: 'The link has been copied to your clipboard.',
        type: 'is-dark',
        duration: 3000,
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' }
      });
    },
    deleteFile() {
      this.showDeleteModal = false;
      this.$store.commit('setIsLoading', true);
      this.$http.delete(`/files/${this.file.slug}`).then(() => {
        this.$store.commit('setIsLoading', false);
        toast({
          message: 'File successfully deleted.',
          type: 'is-dark',
          duration: 3000,
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
        this.$emit('delete')
      }).catch(() => {
        toast({
          message: 'An unknown error occurred.',
          type: 'is-danger',
          duration: 3000,
          dismissible: true,
          animate: { in: 'fadeIn', out: 'fadeOut' }
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.share-menu {
  min-width: 20rem;
}
</style>
