<template>
  <div class="modal is-active">
    <div class="modal-background" @click="onCancel"></div>
    <div class="modal-content">
      <div class="box">
        <h1 class="is-size-4 has-text-centered">Are you sure?</h1>
        <hr>
        <p class="has-text-centered">Are you sure you want to perform this action?</p>
        <div class="field pt-4">
          <div class="button is-primary is-outlined" @click="onCancel">Cancel</div>
          <div class="button is-danger is-pulled-right" @click="onConfirm">Confirm</div>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="onCancel"></button>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onConfirm() {
      this.$emit('confirm');
    }
  }
}
</script>

<style scoped>

</style>
